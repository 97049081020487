import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import GitHubIcon from '@material-ui/icons/GitHub';
import RedditIcon from '@material-ui/icons/Reddit';
import ListItemLink from 'components/ListItemLink';
import React from 'react';

import photo from './cthulhu.png';

const avatarWidthDesktop = '300px';
const avatarWidthMobile = '200px';

const useStyles = makeStyles(theme =>
  createStyles({
    avatar: {
      width: 'auto',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: avatarWidthMobile,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: avatarWidthDesktop,
      },
    },
    github: {
      backgroundColor: '#24292e',
    },
    twitter: {
      backgroundColor: '#1da1f2',
    },
    reddit: {
      backgroundColor: '#ff3300',
    },
    wow: {
      backgroundColor: '#f7b10a',
    },
    discord: {
      backgroundColor: '#7289da',
    },
  })
);

const Home: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      p={1}
    >
      <Grid container justify="center">
        <Grid>
          <Box display="flex" height="100%" alignItems="center" m={1}>
            <Avatar alt="Dreadwail" src={photo} className={classes.avatar} />
          </Box>
        </Grid>
        <Grid>
          <Box display="flex" height="100%" alignItems="center" m={1}>
            <List>
              <ListItemLink
                text="Reddit"
                href="https://www.reddit.com/user/dreadwail"
                isExternal={true}
                icon={RedditIcon}
                iconClassName={classes.reddit}
              />
              <ListItemLink
                text="GitHub"
                href="https://github.com/dreadwail"
                isExternal={true}
                icon={GitHubIcon}
                iconClassName={classes.github}
              />
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
